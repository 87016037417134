import { Ref } from "preact";
import { IState } from "./Main";

export function Avatar({
    state: { size, borderRadius, image, borderColor },
    innerRef,
}: {
    state: IState;
    innerRef: Ref<SVGSVGElement>;
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${size} ${size}`}
            width={size}
            ref={innerRef}
        >
            <defs>
                <mask id="image_mask">
                    <rect x="0" y="0" width="100%" height="100%" fill="black" />
                    <circle
                        cx="50%"
                        cy="50%"
                        r={size / 2 - borderRadius / 2}
                        fill="white"
                    />
                </mask>
                <mask id="ring_mask">
                    <rect x="0" y="0" width="100%" height="100%" fill="black" />
                    <circle
                        cx="50%"
                        cy="50%"
                        r={size / 2 - borderRadius / 2}
                        fill="none"
                        stroke="white"
                        stroke-width={borderRadius}
                    />
                </mask>
                <mask id="ring_mask_inner">
                    <rect x="0" y="0" width="100%" height="100%" fill="black" />
                    <circle
                        cx="50%"
                        cy="50%"
                        r={size / 2 - borderRadius * 0.75}
                        fill="none"
                        stroke="white"
                        stroke-width={borderRadius / 2}
                    />
                </mask>
                <mask id="ring_mask_outer">
                    <rect x="0" y="0" width="100%" height="100%" fill="black" />
                    <circle
                        cx="50%"
                        cy="50%"
                        r={size / 2 - borderRadius * 0.25}
                        fill="none"
                        stroke="white"
                        stroke-width={borderRadius / 2}
                    />
                </mask>
                <radialGradient id="inner_shadow">
                    <stop offset="60%" stop-color="rgba(0, 0, 0, 0)" />
                    <stop offset="100%" stop-color="rgba(0, 0, 0, 0.2)" />
                </radialGradient>
                <radialGradient id="point_white">
                    <stop offset="0" stop-color="rgba(255, 255, 255, 1)" />
                    <stop offset="100%" stop-color="rgba(255, 255, 255, 0)" />
                </radialGradient>
                <radialGradient id="point_dark">
                    <stop offset="0" stop-color="rgba(0, 0, 0, 0.2)" />
                    <stop offset="100%" stop-color="rgba(0, 0, 0, 0)" />
                </radialGradient>
            </defs>

            <image
                href={image}
                width="100%"
                height="100%"
                x="0"
                y="0"
                mask="url(#image_mask)"
            />
            <circle
                cx="50%"
                cy="50%"
                r={size / 2 - borderRadius / 2}
                fill="url(#inner_shadow)"
            />
            <circle
                cx="50%"
                cy="50%"
                r={size / 2 - borderRadius / 2}
                fill="none"
                stroke={borderColor}
                stroke-width={borderRadius}
                mask="url(#ring_mask)"
            />
            <circle
                cx="20%"
                cy="20%"
                r={size}
                fill="url(#point_white)"
                mask="url(#ring_mask_outer)"
            />
            <circle
                cx="40%"
                cy="40%"
                r={size}
                fill="url(#point_dark)"
                mask="url(#ring_mask_inner)"
            />
            <circle
                cx="60%"
                cy="60%"
                r={size}
                fill="url(#point_white)"
                mask="url(#ring_mask_inner)"
            />
            <circle
                cx="70%"
                cy="70%"
                r={size}
                fill="url(#point_dark)"
                mask="url(#ring_mask_outer)"
            />
        </svg>
    );
}
