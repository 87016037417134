import { useRef, useState } from "preact/hooks";
import { saveSvg, saveSvgAsPng } from "save-svg-as-png";
import { Avatar } from "./Avatar";

export interface IState {
    image: string;
    posX: number;
    posY: number;
    zoom: number;
    borderColor: string;
    borderRadius: number;
    size: number;
    name: string;
}

const staticColours = {
    Grey: "#999999",
    Red: "#e20800",
    Gold: "#f3c300",
    Green: "#37a42c",
    Blue: "#2c72c7",
    Purple: "#644a9b",
    Pink: "#e85290",
    Brown: "#8f6b32",
    Black: "#2e3436",
};

export function Main() {
    const svgEl = useRef<SVGSVGElement>(null);
    const [state, setState] = useState<IState>({
        name: "token",
        image: "",
        posX: 0,
        posY: 0,
        zoom: 1,
        borderColor: staticColours.Grey,
        borderRadius: 18,
        size: 256,
    });

    return (
        <div class="mx-auto my-5 container">
            <h1>
                Token Avatar Generator
                <a
                    href="https://lixquid.com"
                    class="btn btn-outline-primary float-end"
                >
                    lixquid.com
                </a>
            </h1>

            <div class="mb-3">
                <label class="form-label">Name</label>
                <input
                    type="text"
                    class="form-control"
                    value={state.name}
                    onChange={(v) =>
                        setState({
                            ...state,
                            name: (v.target as HTMLInputElement).value,
                        })
                    }
                />
            </div>
            <div class="mb-3">
                <label class="form-label">Image (URL or Data URI)</label>
                <input
                    type="text"
                    class="form-control"
                    value={state.image}
                    onChange={(v) =>
                        setState({
                            ...state,
                            image: (v.target as HTMLInputElement).value,
                        })
                    }
                />
            </div>
            <div class="mb-3">
                <label class="form-label">Size</label>
                <input
                    type="number"
                    class="form-control"
                    step="1"
                    min="1"
                    value={state.size}
                    onChange={(v) =>
                        setState({
                            ...state,
                            size: parseInt(
                                (v.target as HTMLInputElement).value
                            ),
                        })
                    }
                />
            </div>
            <div class="mb-3">
                <label class="form-label">Border Colour</label>
                <div class="row">
                    <div class="col-9">
                        <input
                            type="text"
                            class="form-control"
                            value={state.borderColor}
                            onChange={(v) =>
                                setState({
                                    ...state,
                                    borderColor: (v.target as HTMLInputElement)
                                        .value,
                                })
                            }
                        />
                    </div>
                    <div class="col">
                        <select
                            class="form-select"
                            onChange={(v) =>
                                setState({
                                    ...state,
                                    borderColor: (v.target as HTMLSelectElement)
                                        .value,
                                })
                            }
                        >
                            {Object.keys(staticColours).map((k) => (
                                <option
                                    value={
                                        staticColours[
                                            k as keyof typeof staticColours
                                        ]
                                    }
                                    key={k}
                                >
                                    {k}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">Border Size</label>
                <input
                    type="number"
                    class="form-control"
                    step="1"
                    min="1"
                    value={state.borderRadius}
                    onChange={(v) =>
                        setState({
                            ...state,
                            borderRadius: parseInt(
                                (v.target as HTMLInputElement).value
                            ),
                        })
                    }
                />
            </div>

            <div class="w-100 mb-5">
                <button
                    class="btn btn-secondary"
                    onClick={() => {
                        if (!svgEl.current) {
                            return;
                        }
                        saveSvg(svgEl.current, state.name + ".svg");
                    }}
                >
                    Download as SVG
                </button>
                <button
                    class="btn btn-secondary"
                    onClick={() => {
                        if (!svgEl.current) {
                            return;
                        }
                        console.log(svgEl.current);
                        saveSvgAsPng(svgEl.current, state.name + ".png");
                    }}
                >
                    Download as PNG
                </button>
            </div>
            <Avatar state={state} innerRef={svgEl} />
        </div>
    );
}
